<template>
  <nav>
    <LogoBadge></LogoBadge>

    <a class="hamburger" href="#" @click.prevent="toggleShowMenu">
      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M5 5H26" stroke-width="3" stroke-linecap="round"/>
          <path d="M5 15H26" stroke-width="3" stroke-linecap="round"/>
          <path d="M5 25H26" stroke-width="3" stroke-linecap="round"/>
        </g>
      </svg>
    </a>

    <a href="#" class="cart-btn" @click.prevent="clickCart">
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8756 21.9583L13.459 17" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M12.0423 6.375L9.20898 10.625M21.959 6.375L24.7923 10.625" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M19.125 21.9583L20.5417 17" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.375 13.4583V13.4583C7.20274 13.4583 7.91942 14.0332 8.09898 14.8413L9.92894 23.0761C10.2626 24.5777 10.4295 25.3285 10.9779 25.7684C11.5263 26.2083 12.2954 26.2083 13.8337 26.2083H20.1663C21.7046 26.2083 22.4737 26.2083 23.0221 25.7684C23.5705 25.3285 23.7374 24.5777 24.0711 23.0761L25.901 14.8413C26.0806 14.0332 26.7973 13.4583 27.625 13.4583V13.4583" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M27.6257 13.4583H6.37565C5.59325 13.4583 4.95898 12.8241 4.95898 12.0417C4.95898 11.2593 5.59325 10.625 6.37565 10.625H27.6257C28.4081 10.625 29.0423 11.2593 29.0423 12.0417C29.0423 12.8241 28.4081 13.4583 27.6257 13.4583Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>

      <span v-if="cartContentNumber > 0">
        {{ cartContentNumber }}
      </span>
    </a>

    <Transition name="fade">
      <div class="mobile-black-mask" v-if="showMask" @click="toggleShowMenu"></div>
    </Transition>

    <Transition name="slide">
      <div class="nav-menu" v-if="showMenuDiv">
        <div class="nav-menu-content">
  
          <div class="nav-menu-drop" :class="{'active': link.show}" @mouseover="mouseInNavLink(index)" @mouseleave="mouseOutNavLink(index)" v-for="(link, index) in links" :key="index">
            <a href="#" class="nav-menu-drop-title" @click.stop.prevent="toggleActiveIndex(index)">{{ link.title }}</a>
            <div class="nav-menu-drop-list">
              <router-link class="nav-menu-drop-link" v-for="(subLink, subIndex) in link.subLinks" :key="subIndex" :to="subLink.link" >{{ subLink.title }}</router-link>
            </div>
          </div>
  
        </div>
  
        <a href="#" class="menu-close-btn" @click.prevent="toggleShowMenu">✕</a>
      </div>
    </Transition>


  </nav>
</template>

<script>
import LogoBadge from './LogoBadge.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TopNav',
  data() {
    return {
      showMenu: false,
      links: [
        {
          title: '線上訂購',
          show: false,
          subLinks: [
            {
              title: '全部商品',
              link: {
                name: 'ProductCategory',
                params: {
                  category: 'all',
                },
              }
            },
            {
              title: '常溫商品',
              link: {
                name: 'ProductCategory',
                params: {
                  category: 'normal',
                },
              }
            },
            {
              title: '冷藏商品',
              link: {
                name: 'ProductCategory',
                params: {
                  category: 'cold',
                },
              }
            },
            {
              title: '冷凍商品',
              link: {
                name: 'ProductCategory',
                params: {
                  category: 'freeze',
                },
              }
            },
          ]
        },
        {
          title: '服務介紹',
          show: false,
          subLinks: [
            {
              title: '擺攤',
              link: {
                name: 'AboutBooth',
              }
            },
            {
              title: '彌月禮盒',
              link: {
                name: 'AboutBaby',
              }
            },
            {
              title: '客製化',
              link: {
                name: 'AboutCustom',
              }
            },
          ]
        },
        {
          title: '季節限定',
          show: false,
          subLinks: [
            {
              title: '2024中秋禮盒',
              link: {
                name: 'MoonFes2024',
              }
            },
            {
              title: '2024新年禮盒',
              link: {
                name: 'NewYear2024',
              }
            },
          ]
        },
      ],
    };
  },
  props: {

  },
  components: {
    LogoBadge,
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'isMobileSize']),
    ...mapState(['hideNav']),
    ... mapGetters('cart', ['cartContent']),

    showMenuDiv() {
      return !this.isMobileSize || this.showMenu;
    },
    showMask() {
      return this.isMobileSize && this.showMenu;
    },
    cartContentNumber() {
      return this.cartContent.length;
    },
  },
  watch: {
    $route() {
      for (let i=0;i<this.links.length;i++) {
        this.links[i].show = false;
        this.showMenu = false;
      }
    },
  },
  methods: {
    toggleActiveIndex(index) {
      if (this.isMobileSize) {
        for (let i=0;i<this.links.length;i++) {
          if (i === index) {
            this.links[i].show = !this.links[i].show;
          } else {
            this.links[i].show = false;
          }
        }
        if (this.activeIndex === index) {
          this.activeIndex = -1;
        } else {
          this.activeIndex = index;
        }
      }
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
      for (let i=0;i<this.links.length;i++) {
        this.links[i].show = false;
      }
    },
    mouseInNavLink(index) {
      if (!this.isMobileSize) {
        this.links[index].show = true;
      }
    },
    mouseOutNavLink(index) {
      if (!this.isMobileSize) {
        this.links[index].show = false;
      }
    },
    clickCart() {
      if (this.$route.name !== 'Cart') {
        this.$router.push({
          name: 'Cart'
        });
      }
      // alert('官網還在建置中!');
      // console.log('cart');
    }
  }

}
</script>

<style lang="scss" scoped>
nav {
  position: relative;
  display: flex;
  padding: 0 .5rem 0 15px;
  justify-content: space-between;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    z-index: -1;
    background: linear-gradient(180deg, var(--bg-yellow) 50%, transparent 100%);
  }

  a {
    color: #000;
    &:active, &:hover {
      text-decoration: none;
    }
  }

  .hamburger {
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: .5rem;
    svg {
      g {
        stroke: var(--main-hi-light);
      }
    }
  }

  .cart-btn {
    border-radius: 50%;
    background-color: var(--main-hi-light);
    padding: .5rem;
    position: absolute;
    top: 1rem;
    right: 4.5rem;
    >span {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: var(--cart-light-yellow);
      border-radius: 999px;
      font-weight: 1000;
      font-size: .8rem;
      padding: 0 .25rem; 
      box-shadow: 0 2px .5rem #0008;
    }
  }

  .mobile-black-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #0009;
    z-index: 1000;
  }

  .nav-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 80%;
    height: 100vh;
    background-color: #fff;
    padding: 80px 2rem 2rem;
    z-index: 1001;
    .menu-close-btn {
      position: absolute;
      color: #333333;
      font-size: 1.5rem;
      top: 1rem;
      right: 1.5rem;
    }

    .nav-menu-content {
      position: relative;
      display: flex;
      flex-direction: column;
      .nav-menu-drop {
        margin-bottom: 1rem;
        .nav-menu-drop-title {
          font-weight: bold;
          font-size: 1rem;
          padding: .5rem 0;
          color: #333333;
          transition: color .4s ease;
        }

        .nav-menu-drop-list {
          display: none;
          flex-direction: column;
          .nav-menu-drop-link {
            color: #333333;
            opacity: .7;
            padding: .5rem .5rem .5rem 1rem;
          }
        }

        &.active {
          .nav-menu-drop-title {
            color: var(--main-hi-light);
          }

          .nav-menu-drop-list {
            display: flex;
            .nav-menu-drop-link {
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  nav {
    padding: 0 140px 0 8rem;
    align-items: flex-end;

    .hamburger {
      display: none;
    }

    .cart-btn {
      padding: .5rem;
      top: initial;
      bottom: 0;
      right: 2.5rem;
      >svg {
        width: 52px;
        height: 52px;
      }
      >span {
        font-size: 1rem;
      }
    }

    .nav-menu {
      position: relative;
      left: initial;
      top: initial;
      width: initial;
      height: initial;
      padding: .5rem 100px;
      border: solid 1px #666;
      border-radius: 999px;
      // z-index: initial;
      &:not(.show) {
        display: block;
      }

      .menu-close-btn {
        display: none;
      }

      .nav-menu-content {
        flex-direction: row;
        .nav-menu-drop {
          margin-bottom: 0;
          padding: .75rem 2rem;

          .nav-menu-drop-title {
          }

          .nav-menu-drop-list {
            position: absolute;
            background-color: #fff;
            box-shadow: 0 0 4px #0004;
            border-radius: .5rem;
            .nav-menu-drop-link {
              color: #333333;
              opacity: .7;
              padding: 1rem 1.5rem;
              transition: all .4s ease;
              white-space: nowrap;
              &:hover {
                opacity: 1;
                color: var(--main-hi-light);
              }
            }
          }

          &.active {
            .nav-menu-drop-title {
              color: var(--main-hi-light);
            }

            .nav-menu-drop-list {
              display: flex;
              .nav-menu-drop-link {
              }
            }
          }
        }
      }
    }
  }
}


.slide-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}

.slide-enter {
  transform: translate(100%, 0);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity .4s ease, transform .4s ease;
}

.slide-enter-to,
.slide-leave {
  transform: translate(0, 0);
  opacity: 1;
}


.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}
</style>