import axios from 'axios';

const API_BASE_URL = '/api';

export default {
  namespaced: true,
  state: {
    URLS: {

      CREATE_ECPAY_BILL: `${API_BASE_URL}/ecpaybill/create`,
      POST_BILL_CALCULATE: `${API_BASE_URL}/ecpaybill/calculate`,

      GET_PRODUCT_LIST: `${API_BASE_URL}/product/list`,
      GET_PRODUCT_DETAIL: `${API_BASE_URL}/product/detail`,
      

      LOGIN: `${API_BASE_URL}/user/login`,
      LOGINLINE: `${API_BASE_URL}/user/loginByLineCode`,
      REFRESH_TOKEN: `${API_BASE_URL}/user/refresh`,

			GET_ACCOUNT_INFO: `${API_BASE_URL}/user/me`,


    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.auth.token,
          'Cache-Control': 'no-cache',
          // 'Content-Type': 'application/json',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadFilePromise({getters}, {url, filename, file}) {
      const formData = new FormData();
      formData.append(filename, file);
      // const axiosConfig = {
      //   headers: {
      //     'token': getters.axiosConfig.headers.token,
      //     'Cache-Control': 'no-cache',
      //     'Content-Type': 'multipart/form-data',
      //   }
      // };
      const axiosConfig = JSON.parse(JSON.stringify(getters.axiosConfig)); //使用深拷貝, 使其不影響其他引用getters.axiosConfig的未知錯誤
      axiosConfig.headers['Content-Type'] = 'multipart/form-data';
      return new Promise(function(resolve, reject) {
        axios.post(url, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },

    /* 帳單相關 */
    postCreateBillPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.CREATE_ECPAY_BILL,
        data,
      });
    },

    postCalculateBillPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_BILL_CALCULATE,
        data,
      });
    },

    

    /* 商品相關 */
    getProductListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_PRODUCT_LIST,
      });
    },
    getProductDetailPromise({dispatch, state}, productSerial) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_PRODUCT_DETAIL}/${productSerial}`,
      });
    },

    /* */


    postLoginPromise({dispatch, state}, {email, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGIN,
        data: {email, password}
      });
    },

    postLoginLinePromise({dispatch, state}, {code, callbackUrl}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGINLINE,
        data: {code, callbackUrl}
      });
    },

    postRefreshTokenPromise({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESH_TOKEN,
        data: {token}
      });
    },

    getAccountInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_ACCOUNT_INFO,
      });
    },

  },
}
