<template>
  <div id="app" v-if="init">
    <TopNav v-if="!hideNav"></TopNav>
    
    <div class="content">
      <router-view/>
    </div>

    <WebFooter>

    </WebFooter>

    <Transition name="fade">
      <div class="loading-mask" v-if="isStoreBusy">
        <b-spinner></b-spinner>
      </div>
    </Transition>
  </div>
</template>

<script>
import TopNav from './components/nav/TopNav';
import WebFooter from './components/footer/WebFooter.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'AppView',
  metaInfo() {
    return {
      title: '',
      titleTemplate: '%sL\'TAS 拉塔斯點心舖',
      meta: [
        { "http-equiv": 'Cache-control', content: 'public' },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'zh-tw' },
      ]
    };
  },  
  data() {
    return {
      init: true,
    };
  },
  async mounted() {
    // try {
    //   await this.$store.dispatch('auth/initLoginToken');
    //   this.init = true;
    // } catch(e) {
    //   console.error(e);
    // }
    this.readCartFromStorage();
    window.addEventListener("resize", this.resizeHandler);
    document.dispatchEvent(new Event('render-event'));

  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  components: {
    TopNav,
    WebFooter,
  },
  watch: {
    $route(to) {
      if (to.meta.noToTop === undefined || !to.meta.noToTop) {
        window.scrollTo(0,0);
      }
      // console.log(to);
    }
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideNav']),
    // routeName() {
    //   return this.$route.name;
    // },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    ...mapActions('cart', ['readCartFromStorage']),
    resizeHandler() {
      this.$store.commit('SETWINDOWWIDTH', window.innerWidth);
    },
  }
}
</script>


<style lang="scss" scoped>

.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0005;
  opacity: 1;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


</style>

<style>
#app {
  color: #333333;
}
</style>