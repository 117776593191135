<template>
  <div class="footer-nav">
    <div class="nav-left">
      <p class="font-weight-bold mb-2">小貓二三隻工坊</p>
      <p>營業時間：上班日 10:00-17:00</p>
      <p>統一編號：91673069</p>
    </div>

    <div class="nav-right">
      <div class="nav-right-up d-none">
        <ul>
          <li>
            <router-link :to="{name: 'ComingSoon'}">線上訂購</router-link>
          </li>
          <li>
            <router-link :to="{name: 'ComingSoon'}">服務介紹</router-link>
          </li>
          <li>
            <router-link :to="{name: 'ComingSoon'}">查看訂單狀態</router-link>
          </li>
        </ul>
      </div>

      <div class="nav-right-bottom">
        <p class="font-weight-bold mb-2">聯絡我們</p>
        <ul>
          <li>
            <a href="https://www.instagram.com/ltastiandianpu/" target="_blank">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/ltaspatisserie" target="_blank">Facebook</a>
          </li>
          <li>
            <a href="https://lin.ee/XoXSTKt" target="_blank">LINE 官方帳號</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterNav',
  data() {
    return {

    };
  },
}
</script>

<style lang="scss" scoped>
.footer-nav {
  letter-spacing: .2em;
  .nav-left {
    margin-bottom: 3rem;
    p {
      margin-bottom: .2rem;
      text-align: center;
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-right-up {
    margin-bottom: 3rem;
    li {
      text-align: center;
      margin-bottom: .5rem;  
      a {
        color: var(--main-dark);
        font-weight: 1000;
      }
    }
  }

  .nav-right-bottom {
    p {
      text-align: center;
    }
    li {
      text-align: center;
      margin-bottom: .5rem;  
      a {
        color: var(--main-dark);
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    .nav-left {
      p {
        text-align: left;
      }
    }

    .nav-right {
      display: flex;
      column-gap: 4rem;
      .nav-right-up {
        li {
          text-align: left;
        }
      }

      .nav-right-bottom {
        li, p {
          text-align: left;
        }
        li {
          padding-left: 1rem;
        }
      }

    }
  }
}
</style>