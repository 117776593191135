export default {
  namespaced: true,
  state: {
    CART_STORAGE_NAME: 'LTAS_CART',
    cartItems: [
      // {
      //   product: {},
      //   amount: 1
      // }
    ],
  },
  getters: {
    cartContent(state) {
      return state.cartItems;
    },
  },
  mutations: {
    CLEARCART(state) {
      state.cartItems.splice(0);
    },
    ADDTOCART(state, item) {
      for (let i=0;i<state.cartItems.length;i++) {
        const nowItem = state.cartItems[i];
        if (nowItem.product.serial === item.product.serial) {
          nowItem.amount += item.amount;
          state.cartItems.splice(i, 1, nowItem);
          return;
        }
      }
      state.cartItems.push(item);
    },
    CHANGEITEMAMOUNT(state, {index, amount}) {
      const item = state.cartItems[index];
      item.amount = amount;
      state.cartItems.splice(index, 1, item);
    },
    REMOVEFROMCART(state, index) {
      state.cartItems.splice(index, 1);
    },
  },
  actions: {
    readCartFromStorage(context) {
      context.commit('CLEARCART');
      const saved = window.localStorage.getItem(context.state.CART_STORAGE_NAME);
      if (saved !== undefined) {
        const ary = JSON.parse(saved);
        if (ary !== null) {
          for (const item of ary) {
            context.commit('ADDTOCART', item);
          }
        }
      }
    },
    saveCartToStorage(context) {
      window.localStorage.setItem(context.state.CART_STORAGE_NAME, JSON.stringify(context.state.cartItems));
    },
    addItemToCart(context, item) {
      context.commit('ADDTOCART', item);
      context.dispatch('saveCartToStorage');
    },
    changeItemAmount(context, {serial, amount}) {
      for (let i=0;i<context.state.cartItems.length;i++) {
        if (context.state.cartItems[i].product.serial === serial) {
          context.commit('CHANGEITEMAMOUNT', {
            index: i,
            amount,
          });
          break;
        }
      }
      context.dispatch('saveCartToStorage');
    },
    removeItem(context, serial) {
      for (let i=0;i<context.state.cartItems.length;i++) {
        if (context.state.cartItems[i].product.serial === serial) {
          context.commit('REMOVEFROMCART', i);
          break;
        }
      }
      context.dispatch('saveCartToStorage');
    },
    clearCart(context) {
      context.commit('CLEARCART');
      context.dispatch('saveCartToStorage');
    }

    
  }

}
