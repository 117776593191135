<template>
  <footer>
    <div class="container">
      <div class="links">
        <FooterLinks></FooterLinks>
      </div>
      <div class="mb-5">
        <FooterNav></FooterNav>
      </div>
      <p class="text-center"><small>Copyrights 2024 @ L'tas</small></p>
    </div>
  </footer>
</template>

<script>
import FooterLinks from './FooterLinks';
import FooterNav from './FooterNav';
export default {
  name: 'WebFooter',
  data() {
    return {

    };
  },
  components: {
    FooterLinks,
    FooterNav,
  }
}
</script>

<style lang="scss" scoped>
footer {
  padding: 6rem 0 2rem;
  background: linear-gradient(180deg, transparent 0%, var(--bg-yellow) 40%);

  .links {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 768px) {
    padding: 8rem 0 2rem;
    .links {
      margin-bottom: 8rem;
    }
  }
}
</style>
